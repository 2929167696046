import revive_payload_client_hM0UxqlegU from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5_encodi_yf3wjrg4qnsyq3iaehecst4tye/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DSybEbePF1 from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5_encodi_yf3wjrg4qnsyq3iaehecst4tye/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ym1UtpzxSX from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5_encodi_yf3wjrg4qnsyq3iaehecst4tye/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_as8b2Jz1ka from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5_encodi_yf3wjrg4qnsyq3iaehecst4tye/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/onboarding/.nuxt/components.plugin.mjs";
import prefetch_client_b20XVKRBsC from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5_encodi_yf3wjrg4qnsyq3iaehecst4tye/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_4VcHyAgCHx from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_magicast@0.3.5_rollup@4.21.1_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_z54dYLSMUh from "/app/node_modules/.pnpm/nuxt-viewport@2.1.4_magicast@0.3.5_rollup@4.21.1_vue@3.4.38_typescript@5.5.4_/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_PEYZQ7Zdyd from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.5_rollup@4.21.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_aL2afrLdWr from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node_cgoia7zwwqgwwwe4rv2scjasja/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_Nw9jp4hN2z from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5_encodi_yf3wjrg4qnsyq3iaehecst4tye/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_sentry_client_jQYk0bBF56 from "/app/layers/base/plugins/0.sentry.client.ts";
import _1_gigya_plugin_client_U491P69K7H from "/app/layers/base/plugins/1.gigya-plugin.client.ts";
import _2_user_session_validation_client_BYPNrXf3eW from "/app/layers/base/plugins/2.user-session-validation.client.ts";
import bootstrap_client_n243PjUQtC from "/app/layers/base/plugins/bootstrap.client.ts";
import focus_visible_client_JIkamKZ8y6 from "/app/layers/base/plugins/focus-visible.client.ts";
import http_interceptor_9I5iYs0has from "/app/layers/base/plugins/http-interceptor.ts";
import _0_gigya_plugin_client_VZq2p4KDAf from "/app/apps/onboarding/plugins/0.gigya-plugin.client.ts";
import cavalier_client_LYWekonOaz from "/app/apps/onboarding/plugins/cavalier.client.ts";
import image_thumbnail_update_G2rT6oNNpa from "/app/apps/onboarding/plugins/image-thumbnail-update.ts";
export default [
  revive_payload_client_hM0UxqlegU,
  unhead_DSybEbePF1,
  router_Ym1UtpzxSX,
  payload_client_as8b2Jz1ka,
  components_plugin_KR1HBZs4kY,
  prefetch_client_b20XVKRBsC,
  i18n_4VcHyAgCHx,
  plugin_client_z54dYLSMUh,
  plugin_PEYZQ7Zdyd,
  plugin_aL2afrLdWr,
  chunk_reload_client_Nw9jp4hN2z,
  _0_sentry_client_jQYk0bBF56,
  _1_gigya_plugin_client_U491P69K7H,
  _2_user_session_validation_client_BYPNrXf3eW,
  bootstrap_client_n243PjUQtC,
  focus_visible_client_JIkamKZ8y6,
  http_interceptor_9I5iYs0has,
  _0_gigya_plugin_client_VZq2p4KDAf,
  cavalier_client_LYWekonOaz,
  image_thumbnail_update_G2rT6oNNpa
]